// @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200..1000&display=swap');
@import 'ngx-bootstrap/datepicker/bs-datepicker.css';
@import '~font-awesome/css/font-awesome.css';

@mixin scroll($bgColor, $shadow, $display) {
    &::-webkit-scrollbar {
        width: 5px;
        height: 12px;
        box-shadow: none;
        background-color: $bgColor;
        display: $display;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 12px;
        background: transparent;
        box-shadow: $shadow;
    }

    &:hover,
    :focus {
        &::-webkit-scrollbar {
            background-color: rgba(0, 0, 0, 0.12);
        }

        &::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 0 12px rgba(0, 0, 0, 0.25);
        }
    }
}

* {
    font-family: "Mulish", sans-serif;
    @include scroll(transparent, none, unset);
}

.show-scroll-bar {
    @include scroll(rgba(0, 0, 0, 0.12), inset 0 0 0 12px rgba(0, 0, 0, 0.25), unset);
}

.hide-scroll-bar {
    @include scroll(transparent, none, none);
}

.fs-8 {
    font-size: 8px;
}

.fs-10 {
    font-size: 10px;
}

.fs-12 {
    font-size: 12px;
}

.fs-13 {
    font-size: 13px;
}

.fs-14 {
    font-size: 14px;
}

.fs-15 {
    font-size: 15px;
}

.fs-16 {
    font-size: 16px;
}

.fs-18 {
    font-size: 18px;
}

.fs-20 {
    font-size: 20px;
}

.fs-24 {
    font-size: 24px;
}

.fs-28 {
    font-size: 28px;
}
.fs-30 {
    font-size: 30px;
}
.fs-32 {
    font-size: 32px;
}

.fs-36 {
    font-size: 36px;
}

.fs-40 {
    font-size: clamp(20px, 5vw, 40px);
}

.fs-46 {
    font-size: clamp(36px, 5vw, 46px);
    ;
}

.fs-46-sm {
    @media screen and (max-width: 768px) {
        font-size: 36px;
    }
}

.fw-300 {
    font-weight: 300;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.fw-800 {
    font-weight: 800;
}
.hw-8{
    height: 8px;
    width: 8px;
}
.hw-16 {
    height: 16px;
    width: 16px;
}

.hw-20 {
    height: 20px;
    width: 20px;
}

.hw-24 {
    height: 24px;
    width: 24px;
}
.hw-30 {
    height: 30px;
    width: 30px;
}
.hw-32 {
    height: 32px;
    width: 32px;
}

.hw-40 {
    height: 40px;
    width: 40px;
    min-width: 40px;
}
.hw-48{
    height: 48px;
    width: 48px;
    min-width: 48px;
    }
.hw-50 {
    height: 50px;
    width: 50px;
}

.hw-56 {
    height: 56px;
    width: 56px;
}
.hw-64 {
    height: 64px;
    width: 64px;
}
.hw-60 {
    height: 60px;
    width: 60px;
}
.hw-65 {
    height: 65px;
    width: 65px;
}
.hw-80 {
    height: 80px;
    width: 80px;
}
.hw-85 {
    height: 85px;
    width: 85px;
}

.hw-95 {
    height: 95px;
    width: 95px;
}
.hw-120 {
    height: 120px;
    width: 120px;
    min-height: 120px;
}
.hw-135 {
    height: 135px;
    width: 135px;
    min-height: 135px;
}
.hw-140 {
    height: 140px;
    width: 140px;
    min-height: 140px;
}
.hw-180 {
    height: 180px;
    width: 180px;
}
.mxw-85{
    max-width: 85px;
}
.mxw-950 {
    max-width: 950px;
}
.mxw-850{
    max-width: 850px;
  }
.mxw-800{
    max-width: 800px;
}
.mxw-780 {
    max-width: 780px;
}
.mxw-600 {
    max-width: 600px;
}
.mxw-650 {
    max-width: 650px;
}
.mxw-450 {
    max-width: 450px;
}
.mxw-400 {
    max-width: 400px;
}
.mxw-380 {
    max-width: 380px;
}
.mxw-350 {
    max-width: 350px;
}
.mxw-300 {
    max-width: 300px;
}
.mxw-220 {
    max-width: 220px;
}
.mxw-140 {
    max-width: 140px;
}
.mnw-90{
    min-width: 90px;
}
.mnw-135{
    min-width: 135px;
}
.mnw-250{
    min-width: 250px;
}
.mnh-250{
    min-height: 250px;
}
.mxh-300{
    max-height: 300px;
}
.mxh-400{
    max-height: 400px;
}
.grey-text {
    color: #7E7E7E;
}

.dark-grey {
    color: #464747;
}

.bg-lightgrey {
    background: #F8F8F8;
}

.bg-lightf7faf7 {
    background: #f7faf7;
}

.bg-purple {
    background: rgb(116, 67, 255);
}

.position-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.custom-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    top: 0;
    left: 0;
    z-index: 991;
    transition: all .3s ease-in-out;
}

.input_field {
    border: 1px solid #C9CAD9;
    border-radius: 8px;
    padding: 10px 16px;
    margin: 8px 0;

    &:hover,
    &:focus-visible,
    &:focus,
    &:active {
        box-shadow: none;
        border: 1px solid #C9CAD9;
        outline: unset;
    }
}

.input_label {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    transition: 0.2s ease all;
    color: #aaa;
    pointer-events: none;
    transform-origin: left;
}

.input_container {
    position: relative;

    .input_field {
        padding: 20px 16px 10px;
    }
}

.input_container .input_field:focus~label,
.input_container .input_field:not(:placeholder-shown)~label {
    top: 0;
    left: 0;
    transform: translate(16px, 9px) scale(0.7);
}

.input_container .input_field:focus {
    border-color: #007bff;
    outline: none;
}
.input_field[readonly] {
    background-color: #e9ecef;
    opacity: 0.8;
    pointer-events: none;

}
.form-control {
    border: 1px solid #C9CAD9;

    &:hover,
    &:focus-visible,
    &:focus,
    &:active {
        box-shadow: none;
        border: 1px solid #C9CAD9;
        outline: unset;
    }
}

.btn {
    padding: 14px;
    min-width: 100px;
    line-height: 1;

    &:hover,
    &:focus,
    &:active {
        box-shadow: none;
    }

    &.btn-primary {
        background: #275B1F;
        border: 1px solid #275B1F;
        color: #fff;
    }

    &.btn-secondary {
        // background: rgba(255, 199, 55, 1);
        background-color: rgba(255, 199, 55, 1);
        border: 1px solid rgba(255, 199, 55, 1);
        color: #000;
        &.light{
            background: rgba(250, 211, 142, 1);
        }
        &[disabled]{
            background: rgba(228, 228, 228, 1);
            border: 1px solid rgba(228, 228, 228, 1);
            color: rgba(187, 187, 187, 1);
        }
    }

    &.btn-outline {
        border: 1px solid #275B1F;
        background: #fff;
        color: #275B1F;
    }
    &.btn-outline-grey {
        border: 1px solid rgba(147, 147, 147, 1);
        background: #fff;
        color: rgba(147, 147, 147, 1);
    }
    &.btn-default {
        border: 1px solid rgba(208, 213, 221, 1);
        background: #fff;
        color: rgba(70, 71, 71, 1);
    }
    &.btn-blue {
        border: 1px solid rgba(2, 103, 255, 1);
        background: #fff;
        color: rgba(76, 87, 202, 1);
    }

    &.disable {
        opacity: 0.5;
        cursor: unset;
    }
}

.link-text {
    color: #4B58CA;
}

.cursor {
    cursor: pointer;
}

// ----------checkbox
/* Common Styles */
input[type="checkbox"]+label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 30px;
    margin-right: 15px;
    font-size: 14px;
}

input[type="checkbox"]+label:before {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 14px;
    position: absolute;
    top: 0px;
    left: 0;
    border-radius: 5px;
}

input[type="checkbox"] {
    display: none;
}

input[type="checkbox"]:checked+label:after {
    content: "";
    width: 11px;
    height: 6px;
    border-bottom-left-radius: 1px;
    position: absolute;
    transform: scale(1) rotate(-45deg);
    left: 5px;
    top: 6px;
    opacity: 1;
}

.left-tb-border{
    margin: 8px 0;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    &.remove{
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;  
    }
}
.right-tb-border{
    margin: 8px 0;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    &.remove{
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;  
    }
}
input[type="checkbox"]+label:before {
    border: 2px solid #C3C8CD;
    background-color: #fff;
}

/* Primary Checkbox */

input[type="checkbox"].primary-checkbox:checked+label:before {
    background-color: #FAD38E;
    border: 2px solid #FAD38E;
}

input[type="checkbox"].primary-checkbox:checked+label:after {
    border-bottom: 2px solid #161616;
    border-left: 2px solid #161616;
}

/* Secondary Checkbox */
input[type="checkbox"].secondary-checkbox:checked+label:before {
    border: 2px solid #275B1F;
    background: #F7FAF7;
}

input[type="checkbox"].secondary-checkbox:checked+label:after {
    border-bottom: 2px solid #275B1F;
    border-left: 2px solid #275B1F;
}

/* Blue Checkbox */
input[type="checkbox"].blue-checkbox:checked+label:before {
    border: 2px solid white;
    background: #0267FF;
}

input[type="checkbox"].blue-checkbox:checked+label:after {
    border-bottom: 2px solid white;
    border-left: 2px solid white;
}

// ---------------------Radio Buttons

/* Common Styles for Radio Buttons */
input[type="radio"]+label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 30px;
    margin-right: 15px;
    font-size: 14px;
}

input[type="radio"]+label:before {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 14px;
    position: absolute;
    top: 0px;
    left: 0;
    border-radius: 50%;
}

input[type="radio"] {
    display: none;
}

input[type="radio"]:checked+label:after {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    left: 5px;
    top: 5px;
    opacity: 1;
}

/* Unchecked Radio Button */
input[type="radio"]+label:before {
    border: 2px solid #C3C8CD;
    background-color: #fff;
}

/* Primary Radio Button */
input[type="radio"].primary-radio:checked+label:before {
    background-color: #fff;
    border: 2px solid #FFE66D;
}

input[type="radio"].primary-radio:checked+label:after {
    background-color: #FFE66D;
}

/* Secondary Radio Button */
input[type="radio"].secondary-radio:checked+label:before {
    border: 2px solid #275B1F;
    background: #F7FAF7;
}

input[type="radio"].secondary-radio:checked+label:after {
    background-color: #275B1F;
}


.eye-icon {
    top: 22px;
    right: 12px;
    height: 15px;
    width: 15px;
    filter: contrast(0);
}

.overlay {
    background: #0008;
    z-index: 3;
    left: 0;
    top: 0;
}

.fm-spinner {
    background: #183813;
    height: 60px;
    width: 60px;
    animation: rotate-spinner 1s linear infinite;
    filter: drop-shadow(3px 0px 2px #fff);
    // border-top: 3px solid #fff;
    // #ace7b0

}

// .fm-spinner:before {
//     content: "";
//     position: absolute;
//     top: 0;
//     left: 0;
//     height: 100%;
//     width: 100%;
//     border-radius: 50%;
//     border: 5px solid transparent;
//     border-top-color: #fff;
//     transform: rotate(360deg);
//     transition: all 1s ease;
//     animation: rotate-spinner 1s linear infinite;
// }
@keyframes rotate-spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.toaster-wrapper {
    top: 50px;
    right: 20px;
    width: 300px;
    border-radius: 8px;
    z-index: 1061;
    animation: translateX .6s both ease-in-out;

    .close-icon {
        top: 5px;
        right: 5px;
    }

    &.show {
        transform: translateX(0);
    }

    &.success {
        background: #C9F8DC;
        border-top: 3px solid #18B557;

        h1 {
            color: #18B557;
        }
    }

    &.error {
        background: #FDE5E5;
        border-top: 3px solid #EF2727;

        h1 {
            color: #EF2727;
        }
    }

    &.warning {
        background: #FFE7CF;
        border-top: 3px solid #FD952D;

        h1 {
            color: #FD952D;
        }
    }
}

@keyframes translateX {
    from {
        transform: translateX(calc(100% + 20px));
    }

    to {
        transform: translateX(0);
    }
}

.decoration-none {
    text-decoration: none;
    list-style: none;
}

.label-primary {
    color: #0A6B89;
}

.label-success {
    color: #00BC8B;
}

.label-danger {
    color: #FF0909;
}

.label-warning {
    color: #FF902B;
}

input:focus {
    outline: none;
}

.dropdown-options {
    z-index: 2;
    top: 36px;
    border-radius: 10px;
    min-width: 170px;
    box-shadow: 0px 2px 8px -1px black;

    li {
        &:hover {
            background: #A5F3C4;
        }
    }
}

.modal-dialog {
    pointer-events: unset;
}

.switch {
    position: relative;
    display: inline-block;
    height: 24px;
    width: 50px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 20px;

}

.slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked+.slider {
    background-color: #18B557;
}

input:checked+.slider-signature {
    background-color: #0267FF !important;
}

input:checked+.slider:before {
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.radius-4 {
    border-radius: 4px;
}

.radius-6 {
    border-radius: 6px;
}

.radius-8 {
    border-radius: 8px;
}

.radius-16 {
    border-radius: 16px;
}

.round-radius {
    border-radius: 50%;
}

.toggle-arrow {
    transition: all .4s ease-in-out;

    &.rotate {
        transform: rotate(-180deg);
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.dropdown-item.active,
.dropdown-item:focus,
.dropdown-item:hover {
    color: #000;
    background-color: unset;
}

.remove-border.active,
.remove-border:focus,
.remove-border-item:hover {
    color: #000;
    background-color: unset;
}

.theme-border-sm {
    border: 1px solid #183813;
}

.theme-border-md {
    border: 2px solid #183813;
}

.theme-border-lg {
    border: 3px solid #183813;
}

.theme-text {
    color: #183813;
}

.lightgreen-text {
    color: #275B1F
}



.min-w-120 {
    min-width: 120px;
}

.report-detail {
    min-width: 400px;
    z-index: 2;
    transform: translateX(100%);
    transition: 1s ease;

    &.show-report {
        transform: translateX(0);
    }
}

.half-left-radius {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
}

.half-right-radius {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}

.close-report {
    left: -35px;
    box-shadow: -2px 1px 2px 0px rgba(0, 0, 0, 0.13);
}

.top-16 {
    top: 16px;
}
.end-16{
    right: 16px;
}
.min-300 {
    min-width: 300px;
}

.contract-status {
    &.active {
        color: #139145;
    }

    &.overdue {
        color: #E28119;
    }

    &.canceled {
        color: #EF2727;
    }

    &.completed {
        color: #0267FF;
    }
}

.text-blue {
    color: #0267FF;
}
.text-purple {
    color: rgba(111, 6, 160, 1);
}
.text-darkgreen{
    color: rgba(24, 56, 19, 1);
}
.text-darkbrown{
color: rgba(97, 68, 21, 1);
}

.bg-grey {
    background: #F2F7FF;
}

.br-grey {
    border: solid 1px #CACACA;
}

.br-blue {
    border: solid 1px #0267FF;
}
.bg-secondary{
    background: rgba(255, 199, 55, 1) !important;
}
.bg-lightblue{
    background:rgb(2 103 255 / 11%);
}
.theme-bg {
    background: #183813;
}

.theme-green-bg {
    background: linear-gradient(180deg, #0D2306 0%, #183813 100%);
}

.lightred-bg {
    background: rgba(255, 241, 241, 1);
}
.limegreen-bg{
    background: rgba(247, 250, 247, 1);
}
.lightgreen-bg {
    background: rgba(237, 253, 243, 1);
}
.ZI-2{
    position: relative;
    z-index: 2;
}
.side-sliding-wrapper {
    transform: translateY(100%);
    transition: 1s ease;
    z-index: 10;

    &.show {
        transform: translateY(0);
    }
}

.lh-30 {
    line-height: 30px;
}

.lh-27 {
    line-height: 27px;
}

.VIpgJd-ZVi9od-aZ2wEe-wOHMyf-ti6hGc {
    display: none !important;
}

.tooltip-inner {
    background-color: #2B2D29 !important;
    border: none;
    max-width: fit-content;
  }
  
  .tooltip-arrow::before {
    border-top-color: #2B2D29 !important;
  }
  .plan-selected {
    border: solid 1px #18B557 !important;
    position:relative;
}

.plan-selected::before {
    content: "✔";
    height: 35px;
    width: 35px;
    position: absolute;
    top: 12px;
    right: 12px;
    background: #18B557;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 20px;
}

.pagination {
    margin: 0;

    .page-item {
        border: 1px solid #E2E2E2;
        margin: 0 4px;
        border-radius: 4px;
        overflow: hidden;

        .page-link {
            font-size: 13px;
            font-weight: 600;
            color: #000;
            border: none;
        }

        &.active {
            .page-link {
                color: #fff;
                background: rgba(39, 91, 31, 1);
                // border: 1px solid rgba(39, 91, 31, 1);
            }
        }
    }

    .pagination-prev,
    .pagination-next {
        &.disabled {
            opacity: 0.7;
        }
    }
}


.grecaptcha-badge {
    display: none;
}

.drop-shadow{
    box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.02);
}

.blue-theme-color {
    color: #0267FF !important;
}
.w-fit{
    width: fit-content;
}
// ----------------------progress donut-------------------
.donut-container {
    position: relative;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
  }
  
  .donut-progress {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: conic-gradient(
      rgba(24, 181, 87, 1) calc(var(--percentage) * 1%), 
      rgba(228, 228, 228, 1) 0%
    );
  }
  
  .donut-progress::after {
    content: '';
    position: absolute;
    width: 75%;
    height: 75%;
    background: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
  }
  
  .donut-text {
    color: rgba(24, 181, 87, 1);
  }
.black-13{
    color:rgba(13, 19, 33, 1);
}
.black-70{
    color: rgba(70, 71, 71, 1);
}
.black-65{
    color: rgba(65, 65, 65, 1);
}
.black-24{
    color: rgba(24, 24, 24, 1);
}
.black-40{
    color: rgba(40, 40, 40, 1);
}
.black-43{
    color: rgba(43, 48, 56, 1);
}
.black-44{
    color: rgba(44, 44, 44, 1);
}
.black-52{
    color: rgba(52, 64, 84, 1);
}
.black-16{
    color: rgba(16, 24, 40, 1);
}
.grey-32{
    color: rgba(52, 64, 84, 1);
}
.grey-106{
    color: rgba(106, 106, 106, 1);
}
.grey-70{
    color: rgba(70, 71, 71, 1);
}
.grey-126{
    color: rgba(126, 126, 126, 1);
}
.grey-106{
    color: rgba(143, 143, 143, 1);
}
.grey-143{
    color: rgba(143, 143, 143, 1);
    }
.grey-208{
        color: rgba(208, 208, 208, 1);
        }
.opacity-half{
    opacity: 0.8;
}
.rotate-180 {
    transform: rotate(180deg);
}